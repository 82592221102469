@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Red Hat Display";
        font-style: normal;
        font-weight: 400;
        src: url("fonts/red-hat-display-regular.woff") format("woff"),
        url("fonts/red-hat-display-regular.woff2") format("woff2");
    }
    @font-face {
        font-family: "Red Hat Display";
        font-style: normal;
        font-weight: 500;
        src: url("fonts/red-hat-display-medium.woff") format("woff"),
        url("fonts/red-hat-display-medium.woff2") format("woff2");
    }
    @font-face {
        font-family: "Red Hat Display";
        font-style: normal;
        font-weight: 700;
        src: url("fonts/red-hat-display-bold.woff") format("woff"),
        url("fonts/red-hat-display-bold.woff2") format("woff2");
    }
    @font-face {
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 600;
        src: url("fonts/gilroy-semibold.woff2") format("woff2"),
        url("fonts/gilroy-semibold.woff") format("woff");
    }
}

